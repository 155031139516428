import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState, setAuthority } from 'store/auth/userSlice'
import { apiSignIn, apiVerifyOtp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { useNavigate } from 'react-router-dom'
import { setNavMode, setThemeColor, setThemeColorLevel } from 'store/theme/themeSlice'

function useAuth() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { token, signedIn } = useSelector((state) => state.auth.session)

    // Sign-In Function
    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values)

            if (!resp.data.success) {
                throw resp.data
            }

            const user = resp.data.user

            if (user.userType === 1) {
                // Handle userType 1
                const token = user.sessionToken
                dispatch(onSignInSuccess(token))
                dispatch(setUser(user))

                // Default theme settings for userType 1
                dispatch(setThemeColor('indigo'))
                dispatch(setThemeColorLevel('600'))
                dispatch(setNavMode('default'))
                dispatch(setAuthority(['admin']))

                navigate("/home")
                return { status: 'success', message: '' }
            } else {
                // Handle userType !== 1 (OTP sent case)

                return {
                    status: 'otp_sent',
                    message: 'OTP has been sent to registered Email.',
                }
            }
        } catch (errors) {
            return { status: 'failed', message: errors.error }
        }
    }

    // Verify OTP Function
    const verifyOtp = async (values) => {
        try {
            const resp = await apiVerifyOtp(values)

            if (!resp.data.success) {
                throw resp.data
            }

            const user = resp.data.user
            const token = user.sessionToken
            dispatch(onSignInSuccess(token))
            dispatch(setUser(user))

            if (user.brandID) {
                // Brand-specific theme settings for userType other than 1
                const themeColor = user.brandID.themeColor || 'defaultColor'
                const themeColorLevel = user.brandID.colorlevel || 'defaultLevel'
                const navMode = 'themed'
                const authority = [user.role === 1 ? 'calling' : 'dispatch']

                dispatch(setThemeColor(themeColor))
                dispatch(setThemeColorLevel(themeColorLevel))
                dispatch(setNavMode(navMode))
                dispatch(setAuthority(authority))
            }

            navigate("/home")
            return { status: 'success', message: '' }

        } catch (errors) {
            return { status: 'failed', message: errors.error }
        }
    }

    // Sign-Out Function
    const signOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    return {
        authenticated: !!token && signedIn,
        signIn,
        verifyOtp,
        signOut,
    }
}

export default useAuth
