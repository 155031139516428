import ApiService from "./ApiService"

export async function sendEmailWithCSV (data) {
    return ApiService.fetchData({
        url: '/admin/sendEmailWithCSV',
        method: 'post',
        data
    })
}
export async function editAdmin (data) {
    return ApiService.fetchData({
        url: '/admin/editAdmin/'+data.adminID,
        method: 'patch',
        data
    })
}