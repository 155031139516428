import axios from 'axios'
import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store'
import { onSignOutSuccess } from '../store/auth/sessionSlice'
import { useNavigate } from 'react-router-dom'  // Use this for React Router v6

const unauthorizedCode = [401, 403]

const BaseService = axios.create({
    timeout: 60000,
    baseURL: appConfig.apiPrefix,
    withCredentials: true,
})

BaseService.interceptors.request.use(config => {
    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
    const persistData = deepParseJson(rawPersistData)
    
    const accessToken = persistData?.auth?.session?.token

    if (accessToken) {
        config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE} ${accessToken}`
    }
    
    return config
}, error => {
    return Promise.reject(error)
})

BaseService.interceptors.response.use(
    response => response,
    async error => {
        const { response } = error

        if (response) {
            if (unauthorizedCode.includes(response.status)) {
                store.dispatch(onSignOutSuccess())
                
                if (response.status === 403) {
                    const navigate = useNavigate()  // Use this for React Router v6
                    navigate('/login')
                }
            }
        } else {
        }

        return Promise.reject(error)
    }
)

export default BaseService
