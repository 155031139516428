import { createSlice } from "@reduxjs/toolkit";

const date = new Date()

const stateSlice = createSlice({
  name: "dashboard/state",
  initialState: {
    startDate: date,
    endDate: date,
    brandID: "tab1",
  },
  reducers: {
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setBrandID: (state, action) => {
      state.brandID = action.payload;
    },
  },
});

export const { setStartDate, setEndDate, setBrandID } = stateSlice.actions;

export default stateSlice.reducer;
