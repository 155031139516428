import ApiService from "./ApiService"

export async function getBrands (data) {
    return ApiService.fetchData({
        url: '/brand/listBrand',
        method: 'get',
        data
    })
}

export async function getBrandDetails (data) {
    return ApiService.fetchData({
        url: '/brand/getBrand/'+data,
        method: 'get',
    })
}

export async function addBrand (data) {
    return ApiService.fetchData({
        url: '/brand/addBrand',
        method: 'post',
        data
    })
}

export async function updateBrand (data) {
    return ApiService.fetchData({
        url: '/brand/editBrand/'+data._id,
        method: 'patch',
        data: data.formData
    })
}

export async function deleteBrand (data) {
    return ApiService.fetchData({
        url: '/brand/deleteBrand/'+data._id,
        method: 'delete',
    })
}