import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import dashboard from '../views/Dashboard/store/index'

const rootReducer = (asyncReducers) => (state, action) => {
    if (action.type === 'auth/session/onSignOutSuccess') {
        state = undefined;
      }
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        dashboard,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
